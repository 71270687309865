import React from 'react';
import { withNamespaces } from 'react-i18next';
import cl from 'classnames';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import s from './Application.module.scss';
import Link from '../Link';
import Api from '../../utils/api';
import { removeTags } from '../../utils/strings';

import img from '../../assets/img/applications/android-app.png';
import img2 from '../../assets/img/applications/android-app-x2.png';
import img3 from '../../assets/img/applications/android-app-x3.png';

import card9Img from '../../assets/img/applications/connect.svg';
import card4Img from '../../assets/img/applications/noun-swedish-912926.svg';
import card12Img from '../../assets/img/applications/noun-vpn-security-1531495.svg';
import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card5Img from '../../assets/img/applications/global.svg';
import card6Img from '../../assets/img/applications/group-10.svg';
import SiteTitle from '../PageHeaderTitle';


export const ApplicationAndroid = withNamespaces()(({ t, gatsbyUrl, pythonUrl, i18n, brand }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_android')}
      text={t('page_applications:text_1_android')}
      list={t('page_applications:list_android')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_android')}
      img_title={`${brand.name} ${t('page_applications:image_alt_android')}`}
      maxHeight='500px'
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_android'))} />
      <div className={s.buttons}>
          <Link className={cl(s.button, s.button_accent)} to={api.applicationAndroidApkUrl()}>
            {`${t('page_applications:download')} .apk`}
          </Link>
          <Link className={cl(s.button, s.button_dark)} to={api.applicationAndroidGuideUrl()}>
            {t('page_applications:setup_guides')}
          </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesAndroid = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_12_title'), text: t('page_applications:card_12_text'), imgSrc: card12Img },
    { title: t('page_applications:card_4_title'), text: t('page_applications:card_4_text'), imgSrc: card4Img },
    { title: t('page_applications:card_9_title'), text: t('page_applications:card_9_text'), imgSrc: card9Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewAndroid = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_1')}
    author={t('page_applications:anonymous')}
  />
));
