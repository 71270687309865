import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../../layouts/index';
import PageHeader from '../../components/PageHeader';
import PageFooter from '../../components/PageFooter';
import PageContent from '../../styled/PageContent';
import ApplicationsBody from '../../components/AppicationsPage/ApplicationsBody';
import { ApplicationAndroid, ListOfFeaturesAndroid, ReviewAndroid } from '../../components/AppicationsPage/ApplicationAndroid';

class ApplicationAndroidPage extends React.Component {
  render() {
    const { gatsbyUrl, pythonUrl, brand, affiliateUrl } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <PageHeader
          brand={brand}
          gatsbyUrl={gatsbyUrl}
          pythonUrl={pythonUrl}
        />
        <PageContent>
          <ApplicationsBody
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
          >
            <ApplicationAndroid
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              brand={brand}
            />
            <ListOfFeaturesAndroid />
            <ReviewAndroid />
          </ApplicationsBody>
        </PageContent>
        <PageFooter
          gatsbyUrl={gatsbyUrl}
          pythonUrl={pythonUrl}
          brand={brand}
          location={location}
          affiliateUrl={affiliateUrl}
        />
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query ApplicationAndroidPageQuery {
    site {
      siteMetadata {
        brand {
          name
          nameCom
          twitter
          facebook
        }
        gatsbyUrl
        pythonUrl
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <ApplicationAndroidPage data={data} location={location} />}
  />
);
